<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      
      max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5"> {{item.name}} </span>&nbsp; - &nbsp;<span class="text-h5"> Frame {{item.frame}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col
                cols="12"
                align="center"
                
              >

                <v-textarea
                  v-model="text"
                  label="Descrição"
                  required
                  outlined
                  hide-details
                >

                </v-textarea>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props:['item', 'open'],
    data: () => ({      
      dialog: false,
    }),
    computed: {
      text:{
        get(){
          return this.item.name === 'Template 1' ? this.$store.state.news.text : this.item.description
        },
        set(v){
          this.$nextTick(()=>{
            if(['Template 1'].includes( this.item.name)){
              this.$store.state.news.text = v
            }
            this.$emit('description', v)
          })
        }
      }
    },
    methods:{
      save(){
        this.dialog = false
      },

      // changeImage(img, template, frame, field){
      //   const reader = new FileReader();
      //   reader.addEventListener('load', (event) => {
      //     this.getUrlImage(event.target.result).then(url=>{
      //       this.$store.state.templates[template][frame][field] = url
      //       this.item[field] = url
      //       img.target.value=null
      //       //this.dialog = false
      //       //this.$nextTick(()=>this.dialog = true)
      //     })
      //   });
      //   reader.readAsDataURL(img.target.files[0]);
      // }
    },
    watch:{
      open(v){
        this.dialog = v
      },

    }
  }
</script>