import axios from 'axios'
import baseUrl from './baseUrl'

const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}

const headers = {token: localStorage.getItem('token')}
const notice = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const getNews = (options) => notice.get('/news', {params: options}).then(({data}) => data).catch(errors)

export const getNewsById = id => notice.get('/news/'+id).then(({data}) => data).catch(errors)

export const createNews = news => notice.post('/news/', news).then(({data}) => data.message).catch(errors)

export const deleteNews = id => notice.delete('/news/'+id).then(({data}) => data.message).catch(errors)

export const updateNews = news => notice.put('/news/'+(news[0] || {})._id||0, news).then(({data}) => data.message).catch(errors)