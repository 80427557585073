<template>
  <v-row>
    <v-col>
      <v-text-field outlined :title="'Cole aqui o link de mídia ou faça upload clicando no botão ao lado'"
        label="Cole aqui o link de mídia ou faça upload" v-model="$store.state.news.link" :rules="validateLink" required
        small></v-text-field>
    </v-col>
    <v-col>
      <v-file-input class="file" label="Carregar mídia" filled prepend-icon="mdi-camera" :accept="$store.state.news.mediaType == 'Imagem'
          ? 'image/*'
          : 'video/*'
        " @change="changeFile" :loading="loadingFile"></v-file-input>
    </v-col>
  </v-row>
</template>

<script>
import { sendImageAds, sendVideoAds, getFile } from "../../services/repository";
const timeout = time => new Promise(resolve => setTimeout(resolve, time || 3000))
export default {
  data: () => ({
    loadingFile: false,
    ticket: '',
    validateLink: [
      (v) =>
        (v || []).length > 0 || "Link de mídia para este anúncio é obrigatório",
    ],
  }),
  methods: {
    async loopGetFile() {
      
      const data = await getFile(this.ticket).catch(() => false)
      if (!data) {
        this.loadingFile = false;
        this.$store.commit(
          "failed",
          "Falha ao carregar arquivo, verfica se o arquivo corresponde a uma imagem ou vídeo ou você pode ter perdido acesso à cloudnary"
        );
        return this.loadingFile = false;
      }
      if (data && data.url) {
        this.loadingFile = false;
        this.readonlyFile = true;
        this.$store.commit("success", "URL da mídia gerada com sucesso");
        return this.$store.state.news.link = data.url
      }
      await timeout()
      this.loopGetFile()
    },
    sendVideo(data) {
      sendVideoAds(data)
        .then((status) => {
          this.ticket = status.ticket
          return this.loopGetFile()
        })
        .catch(() => {
          this.loadingFile = false;
          this.$store.commit(
            "failed",
            "Falha ao carregar arquivo, verfica se o arquivo corresponde a uma imagem ou vídeo ou você pode ter perdido acesso à cloudnary"
          );
        });
    },
    sendImage(data) {
      sendImageAds(data)
        .then((url) => {
          //const urlMount = url.split('upload/')
          this.$store.state.news.link = url.url; //urlMount[0] + 'upload/c_fill,g_auto,h_1920,w_1080/' + urlMount[1]
          this.loadingFile = false;
          this.$store.commit("success", "URL da mídia gerada com sucesso");
          this.readonlyFile = true;
        })
        .catch(() => {
          this.loadingFile = false;
          this.$store.commit(
            "failed",
            "Falha ao carregar arquivo, verfica se o arquivo corresponde a uma imagem ou vídeo ou você pode ter perdido acesso à cloudnary"
          );
        });
    },
    async changeFile(data) {
      if (!data) return (this.$store.state.news.link = "");
      this.loadingFile = true;
      
      if (this.$store.state.news.mediaType == "Imagem") {
        this.sendImage(data);
      } else {
        this.sendVideo(data);
      }
    },
  },
  watch:{
    loadingFile(v){
      this.$store.commit('setLoading', v)
    }
  }
}
</script>

<style lang="scss" scoped></style>