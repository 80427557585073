var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.$store.state.templates.items,"sort-by":"calories","hide-default-footer":"","options":{itemsPerPage: 10000}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","disabled":item.frame === 2},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(index)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)),(false)?_c('span',[_vm._v(_vm._s(item))]):_vm._e()]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.changeView(item)}}},[_vm._v("mdi-eye")])]}},{key:"no-data",fn:function(){return [_vm._v("Nenhum Template selecionado")]},proxy:true}],null,true)}),_c('Edit',{attrs:{"item":_vm.editedItem,"open":_vm.openEdit},on:{"description":_vm.setDescription}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }