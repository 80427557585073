<template>
  <div class="content">
    <img
      src="/img/territorio.png"
      style="width: 300px"
      class="logo-top"
      alt=""
    />
    <div class="dec" :style="{ 'border-color': $store.state.news.color }">
      <img :src="$store.state.news.img" alt="" width="1080" height="1000" />
      <div
        class="copyright-image"
        style="font-size: 25pt"
        v-if="$store.state.news.copyright"
      >
        <b>Foto:</b> {{ $store.state.news.copyright }}
      </div>
    </div>

    <div
      class="dot"
      :style="{ 'background-color': $store.state.news.color }"
    ></div>
    <div class="text" v-html="$store.state.news.text.toUpperCase()"></div>

    <div class="logo">
      <img :src="$store.state.news.logo" width="200" alt="" />
    </div>
    <Qr :color="$store.state.news.color"  />
    <div class="foot" :style="`background: ${$store.state.news.color}; color: ${($store.state.news.user || {}).textColor || '#fff'}`">
      <div class="textfoot"><b>FONTE</b> {{ link }}</div>
    </div>
  </div>
</template>

<script>

import Qr from "../../cards/Qr.vue"
export default {
  components: { Qr },
  props: { template: { type: Object, default: () => ({ frame: "Frame 1" }) } },
  data() {
    return {
      logo: "",
      previewZoom: 0.3,
      currentTemplate: {},
    };
  },

  computed: {
    showPreviewDefault() {
      return !this.$store.state.news.templates.length;
    },
    link() {
      try {
        const url = new URL(this.$store.state.news.link);
        return url.origin;
      } catch (e) {
        return this.$store.state.news.link;
      }
    },
  },
  mounted(){
    setTimeout(()=>{
      this.$store.state.templates["Template 5"].stop ||  this.$emit('end') || this.$emit('end', this.template.index)
      }, this.$store.state.news.time * 1000)
  }
};
</script>
<style scoped>

.copyright-image {
  position: absolute;
  text-shadow: 2px 2px #000;
  height: 50px;
  bottom: 70px;
  left: 50px;
  color: white;
}
.logo-top {
  position: absolute;
  width: 150px;
  left: 5px;
}
.logo {
  position: absolute;
  bottom: 180px;
  left: 20px;
  background: white;
}
.textfoot {
  color: white;
  position: absolute;
  top: 50px;
  left: 20px;
  font-family: arial;
  font-size: 20pt;
}

.foot {
  position: absolute;
  bottom: -80px;
  width: 1100px;
  height: 250px;
  left: -5px;
  transform: rotate(-5deg);
}
.foot >>> * {
  transform: rotate(5deg);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: arial;
}
.dec {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-top: 135px;
  left: 0;
  width: 1188px;
  height: 768px;
  /* background-color: rgb(228, 128, 128); */
  margin-left: -54px;
  border-bottom-left-radius: 0;
  border-top: solid 20px;
  border-bottom: solid 20px;
  border-top-left-radius: 324px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 324px;
  transform: rotate(-6deg);
}
.dec > * {
  transform: rotate(6deg);
  margin-top: -100px;
}

.dot {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 850px;
  top: 834px;
  border-radius: 50px;
}
.text {
  margin-top: 100px;
  padding: 30px;
  font-weight: bold;
  font-size: 40pt;
  font-family: arial;
}
</style>
