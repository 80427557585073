<template>
  <div
    class="content"
    :style="{
      'background-image': `url(${img})`,
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
    }"
  >
    <Logos />
    <dir :class="['text', textClose]">
      <span>{{ text }}</span>
    </dir>
  </div>
</template>

<script>
import Logos from "./logos.vue";
export default {
  components: { Logos },

  data: () => ({
    textClose: "",
    title: "",
    text: "",
    stopWrite: false,
  }),
  computed: {
    img() {
      return (
        this.$store.state.news.img.replace("1000", "1920") ||
        "/img/fundo_homem.png"
      );
    },
    originalText() {
      return this.$store.state.news.text.length > 117
        ? this.$store.state.news.text.slice(0, 117) + "..."
        : this.$store.state.news.text ||
            "O saber preto para combater as mudanças climáticas.";
    },
  },

  watch: {
    originalText(v) {
      this.stopWrite = true;
      this.text = v;
    },
  },

  methods: {
    closeText() {
      this.textClose = "text-close";
    },

    async write() {
      for (const letra of this.originalText) {
        if (this.stopWrite) break;
        await this.timeout(this.$store.state.previews.timeWrite);
        this.text += letra;
      }
      await this.timeout(this.$store.state.previews.timeStage1);
      this.closeText();
      await this.timeout(1000);
      this.$emit("end");
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    setTimeout(() => {
      this.write();
    }, 50);
  },
};
</script>

<style  scoped>
.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: tn;
  overflow: hidden;
}

.author {
  position: absolute;
  top: 63.5%;
  width: 100%;
  text-align: center;
  transition: all 1s ease;
  color: black;
  font-size: 65pt;
  font-weight: bold;
}

.author span {
  background: #fff;
  padding: 3px;
}

.text {
  position: absolute;
  top: 60%;
  font-size: 65pt;
  text-align: center;
  opacity: 1;
  transition: all 1s ease;
  padding-left: 38px;
  padding-right: 38px;
}

.text span {
  background-color: #fff;
  color: black;
  padding: 20px;
  line-height: 120px;
}

.text-close {
  top: 110%;
}

.last-text {
  position: absolute;
  bottom: 50px;
  text-align: center;
  padding: 100px;
  font-family: "Courier New", Courier;
  font-weight: bold;
}
</style>