import axios from 'axios'
import baseUrl from './baseUrl'

const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}
const headers = {token: localStorage.getItem('token')}
const crowller = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const changeCrowller = (url) => crowller.post('/crowller/', {link: url}).then(({data}) => data)
.catch(errors)