<template>
<div>
  <v-data-table
    :headers="headers"
    :items="$store.state.templates.items"
    sort-by="calories"
    class="elevation-1"
    hide-default-footer
    :options="{itemsPerPage: 10000}"
  >
    <template v-slot:top>

    </template>
    <template #[`item.actions`]="{item, index }">
      <v-btn icon :disabled="item.frame === 2" @click="editItem(item)">

      <v-icon   >mdi-pencil</v-icon>
      </v-btn>

      <v-icon
        
        @click="deleteItem(index)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template #[`item.index`]="{item, index}">
      {{index + 1}}<span v-if="false">{{item}}</span>
    </template>

    <template #[`item.view`]="{item}">
      <v-icon @click="changeView(item)">mdi-eye</v-icon>
    </template>

    <template #no-data>Nenhum Template selecionado</template>
 
  </v-data-table>
  <Edit :item="editedItem" :open="openEdit" @description="setDescription" />
  </div>
</template>

<script>
import Edit from './edit.vue'
  export default {
    components:{Edit},
    data: () => ({
      openEdit: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Sequência', value: 'index', sortable: false,  },
        { text: 'Visualizar', value: 'view', sortable: false,  },
        {
          text: 'Template',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Frame', value: 'frame', sortable: false,  },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
        description: ''
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    methods: {
      changeView(item){
        this.$store.commit('templates/setCurrentFrame', item)
        Object.keys(this.$store.state.templates[item.name][item.frame]).forEach(key => {
          if(key !== 'id') this.$store.state.templates[item.name][item.frame][key] = item[key] || ''
        })
      },
      initialize () {
        this.desserts = this.data
      },

      editItem (item) {
        this.editedItem = item
        this.openEdit = false
        this.$nextTick(()=>this.openEdit = true)
      },

      deleteItem (index) {
        this.$store.state.templates.items.splice(index, 1)
      },

      setDescription(v){
        this.editedItem.description = v
        // this.$store.state.news.description = v
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    }
  }
</script>