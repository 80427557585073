<template>
  <div :class="['content']" :style="`background: ${$store.state.news.color}; color: ${($store.state.news.user || {}).textColor || '#fff'}`">
    <Logo :src="$store.state.news.logo" />
    <div class="text">{{ text }}</div>
    <div :class="['img', showSmall, overflow]" :style="`border: 10px solid ${$store.state.news.color};`">
      <Qr :showLabel="false" code="http://google.com" :color="'#fff'" />
    </div>


  </div>
</template>

<script>
import Logo from './logos.vue'
import Qr from './Qr.vue'
export default {
  components: {Qr, Logo},
  data: () => ({
    showSmall: '',
    overflow: '',
    text: "",
    stopWrite: false
  }),
  computed:{
    originalText(){
      return 'Aponte a câmera do seu celular para o código QR abaixo e confira a reportagem completa!'
    }
  },
  watch:{
    originalText(v){
      this.stopWrite = true
      this.text = v
    }
  },
  methods: {
    async write() {
      const text = this.originalText
      let total = text.length;
      while (total) {
        if(this.stopWrite) break;
        this.text += text[text.length - total]
        total--;
        await this.timeout(this.$store.state.previews.timeWrite);
      }

      await this.timeout(this.$store.state.previews.timeStage2)

      this.$store.state.templates["Template 3"].stop || this.$emit('end')
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    setTimeout(()=>this.showSmall='show-small',50)
    setTimeout(()=>this.overflow='overflow', 1000)
    this.write();
  },
};
</script>

<style  scoped>

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 47pt;
  font-family: tn;
}

.content *{
  transition: all .5s ease;
}

.logo{
  position: absolute;
  width: 300px;
  height: 300px;
  border: 1px solid;
}

.logo1{
  top: 1050px;
  left: 390px
}

.logo2{
  bottom: 200px;
  left: 100px;
  width: 400px;
  height: 200px;
}

.logo3{
  bottom: 200px;
  right: 100px;
  width: 400px;
  height: 200px;
}

.text {
  position:absolute;
  top: 30%;
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
}

.img {
  position:absolute;
  top: 60%;
  left: calc(50% - 300px);
  transform: translate(0, 0);
  animation: wave 10s infinite linear;
  overflow: hidden;
  /* transition: all 0.5s; */
  background: #fff;
  /* border-radius: 100% 30% 80% 50%; */
}

.show-small{
  height: 600px;
  width: 600px;
}

.overflow{
  overflow: initial
}

@keyframes wave {
  0% {
    border-radius: 60% 80% 80% 50%;
  }

  20% {
    border-radius: 100% 50% 50% 80%;
  }

  40% {
    border-radius: 30% 70% 10% 70%;
  }

  60% {
    border-radius: 88% 50% 80% 50%;
  }

  80% {
    border-radius: 60% 80% 90% 100%;
  }

  100% {
    border-radius: 60% 80% 80% 50%;
  }
}
</style>