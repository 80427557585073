<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
    <template #activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">Templates</v-btn>
    </template>
    <template #default="dialog">
      <v-card >
        <v-card-title color="primary">
          <v-spacer /> Escolha os Templates para esta Notícia <v-spacer />
          <!-- <v-btn color="green" dark @click="save(dialog)">Salvar</v-btn> -->
          <span class="pr-5" />
          <v-btn @click="save(dialog)" color="blue" dark>ok</v-btn>
        </v-card-title>
        <v-container>
          <v-row justify="center">
            <v-col align="center">
              <div>Template 1</div>
              <div class="templates">
                <Template1 />
              </div>
              <v-row
                no-gutters
                class="pt-2"
                justify="center"
                style="max-width: 213px"
              >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({name: 'Template 1', frame: 1})"
                    >Frame 1</v-btn
                  ></v-col
                >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 1', frame: 2 })"
                    >Frame 2</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>

            <v-col align="center">
              <div>Template 2</div>
              <div class="templates">
                <Template2 />
              </div>
              <v-row
                no-gutters
                class="pt-2"
                justify="center"
                style="max-width: 213px"
              >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({name: 'Template 2', frame: 1})"
                    >Frame 1</v-btn
                  ></v-col
                >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 2', frame: 2 })"
                    >Frame 2</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>

            <v-col align="center">
              <div>Template 3</div>
              <div class="templates">
                <Template3 />
              </div>
              <v-row
                no-gutters
                class="pt-2"
                justify="center"
                style="max-width: 213px"
              >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 3', frame: 1})"
                    >Frame 1</v-btn
                  ></v-col
                >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 3', frame: 2 })"
                    >Frame 2</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>

            <v-col align="center">
              <div>Template 4</div>
              <div class="templates">
                <Template4 />
              </div>
              <v-row
                no-gutters
                class="pt-2"
                justify="center"
                style="max-width: 213px"
              >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 4', frame: 1 })"
                    >Frame 1</v-btn
                  ></v-col
                >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 4', frame: 2 })"
                    >Frame 2</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>

            <v-col align="center">
              <div>Template 5</div>
              <div class="templates">
                <Template5 />
              </div>
              <v-row
                no-gutters
                class="pt-2"
                justify="center"
                style="max-width: 213px"
              >
                <v-col cols="6" align-self="center"
                  ><v-btn
                    small
                    color="primary"
                    @click="addFrames({ name: 'Template 5', frame: 1 })"
                    >Frame 1</v-btn
                  ></v-col
                >
               
              </v-row>
            </v-col>

            
          </v-row>
        </v-container>
        <v-container fluid class="pb-10">
          <v-sheet width="500" class="mx-auto mt-10">
            <TemplatesSelected />
          </v-sheet>
        </v-container>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Template1 from "./template1/index.vue";
import Template2 from "./template2/index.vue";
import Template3 from "./template3/index.vue";
import Template4 from "./template4/index.vue";
import Template5 from "./template5/index.vue";
import TemplatesSelected from "./templates-selected/index.vue";
export default {
  components: {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    TemplatesSelected,
  },
  data: () => ({
    frames: [],
    dialog: false,
  }),
  methods: {
    save(dialog) {
      this.$store.state.news.templates = this.$store.state.templates.items;
      dialog.value = false
    },
    addFrames(template) {
      this.$store.state.templates.items.forEach(item => item.last = false)
      const lastParagraphUsed = (this.$store.state.templates.items.filter(item => !!item.description).slice(-1)[0] || {}).description || ''
      const nextParagraphIndex = this.$store.state.news.paragraphs.indexOf(lastParagraphUsed)
      const newTemplate = {
        id: this.$store.state.templates.items.length + 1,
        description: (template.name == 'Template 1' || template.frame == 2) ? '':(nextParagraphIndex === -1 ? this.$store.state.news.paragraphs[0] : this.$store.state.news.paragraphs[nextParagraphIndex+1] || ''),
        last: true,
        img: this.$store.state.news.img,
        name: template.name,
        frame: template.frame
        }
      this.$store.state.templates.items.push(newTemplate)      
    },
  },
  watch: {
    dialog(v) {
      if (!v) this.$emit("closed");
      this.$store.commit("templates/setStop", v);
    },
  },
};
</script>
<style scoped>
.templates {
  width: 1080px;
  height: 1920px;
  position: relative;
  zoom: 0.18;
  overflow: hidden;
}
.font-bold {
  font-weight: bold;
}
</style>