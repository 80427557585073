<template>
    <div class="slider">
      
        <div :class="['slide', slideUp]">
          <img
            :src=" ['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
    </div>
</template>

<script>
export default {
  props:["notAnimation"],
  data:()=>({
    slideUp: ''
  }),
  mounted(){
    if(this.notAnimation)return this.slideUp = 'slide-up'
    setTimeout(()=>this.slideUp = 'slide-up', 10)
  }
};
</script>

<style  scoped>

.slider {
  overflow: hidden;
  position: absolute;
  height: 800px;
  width: 100%;
  top: 0;
  
}

.slide {
  top: -200px;
  width: 400px;
  position:absolute;
  left: calc(50% - 200px);
  transition: all 1s ease-in-out;
}

.slide-up{
  top:5%;
}
</style>