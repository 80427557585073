<template>
  <div>
    <v-row class="pl-2 pr-2" justify="center" align="center">
      <v-col md="4"><news /></v-col>
      <v-col md="4"><preview-site></preview-site></v-col>
      <v-col md="4"><preview /></v-col>
    </v-row>
  </div>
</template>

<script>
import news from "../../components/cards/news.vue";
import preview from "../../components/cards/preview.vue";
import previewSite from "../../components/cards/previewSite";
import { getNewsById } from "../../services/news";
// @ is an alias to /src

export default {
  name: "News",
  components: { news, preview, previewSite },
  beforeDestroy() {
    this.$store.state.news = Object.assign({}, this.$store.state.originalNews);
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.$store.state.news.edited = true;
      if (!this.$store.state.news._id) {
        this.$store.state.loading = true;
        getNewsById(id)
          .then((data) =>{
            this.$store.commit('templates/setItems', data.templates)
            
            Object.keys(data).forEach((key) => {
              this.$store.state.news[key] = data[key];
              this.$store.state.news.userSelected = data.user._id;
              this.$store.state.loading = false;
              this.$store.state.templates.items = data.templates || [];
              
            })}
          )
          .catch((e) => {
            console.error(e)
            this.$store.commit("failed", "Falha ao buscar dados da notícia");
            this.$store.state.loading = false;
            this.$router.push("/news/list");
          });
      }
    }
    if (!this.$store.state.news.edited) {
      this.$store.state.news.color = this.$store.state.user.color;
      this.$store.state.news.logo = this.$store.state.user.logo;
    }
  },
};
</script>

<style scoped>
.p-2 {
  padding: 8px !important;
}
</style>