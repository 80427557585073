<template>
<div>
    <div class="slider">

        <div :class="['slide slide2', slideUp]">
          <img 
            :src="src || (['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png')"
            width="400"
            alt=""
          />
        </div>
        
      
    </div>
  </div>
</template>

<script>
export default {
  props:['close', 'src'],
  data:()=>({
    slideUp: '',
    slideUp2: ''
  }),
  mounted(){
    setTimeout(()=>{
      this.slideUp = 'slide-up'
      //setTimeout(()=>this.slideUp2 = 'slide-up', 200)
      }
      , 50)
  },
  watch:{
    close(v){
      if(v){
        this.slideUp = ''
      }
    }
  }
};
</script>

<style  scoped>

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 3));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 3));
  }
}



.slider {
  overflow: hidden;
  position: relative;
  height: 800px;
  width: 100%;
  
}

.slide {
  width: 400px;
  position:absolute;
}

.slide2{
  left: calc(50% - 200px);
  top: -500px;
  transition: all 2.2s ease-in-out;
}

.slide-up{
 top:5%; 
}
</style>