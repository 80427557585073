var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",style:(("background: " + (_vm.$store.state.news.color) + "; color: " + ((_vm.$store.state.news.user || {}).textColor || '#fff')))},[_c('Logos',{attrs:{"close":_vm.closeLogo}}),_c('div',{class:['text', _vm.closeRight],domProps:{"innerHTML":_vm._s(_vm.text)}}),_c('div',{ref:"background",class:['img', _vm.closeLeft],style:({
      'background-image': ("url('" + ((
          _vm.$store.state.news.templates.find(
            function (item) { return item.name == 'Template 3'; }
          ) || {}
        ).img || require('@/assets/teste.jpg')) + "')"),
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
    })}),_c('div',{class:['author-status', _vm.closeLeft]},[(_vm.authorRoleText.length)?_c('span',{staticStyle:{"background":"#fff","color":"#000"}},[_vm._v(_vm._s(_vm.authorRoleText))]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }