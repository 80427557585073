<template>
  <div ref="qrcode"  class="qrcode d-flex justify-center mb-6" :style="{ border: 'solid 20px ' + color }">
    <v-container class="pa-0" style="margin-top: -20px">
      <v-row no-gutters justify="center">
        <v-col align-self="center" ><div class="leiamais" >LEIA MAIS</div></v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text-center">

    <img src="/img/qrcode.JPG" alt="teste" width="150rem" />
        </v-col>
      </v-row>
    </v-container>
    
  </div>
</template>

<script>
export default {
  props:["color"]
};
</script>

<style scoped>
.qrcode{
  z-index: 1;
  position: absolute;
  bottom: 200px;
  width: 250px;
  height: 250px;
  overflow: hidden;
  /* box-shadow: 3px 3px 3px rgba(0,0,0,0.7); */
  right: 50px;
  border-radius: 30px;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;
}

.leiamais{

  font-family: arial;
  text-align: center;
  
  font-weight: 900;
  font-size: 20pt;
  font-family: monospace;
}
</style>