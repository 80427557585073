<template>
  <div class="content" :style="{'background-image': `url(${img})`, 'background-repeat': 'no-repeat', 'background-size': '100% 100%'}">
    <div :class="['background-black', show]" ></div>
    <div :class="['logo logo3', logoShow]"><img :src="$store.state.news.logo" width="100%"></div>
    
    <div class="text">{{ text }}</div>

    <div :class="['img', showSmall, overflow]">
      <Qr :showLabel="false" code="http://google.com" :color="'#fff'" />
    </div>
  </div>
</template>

<script>
import Qr from './Qr.vue'
export default {
  components: {Qr},
  data: () => ({
    show: '',
    logoShow: '',
    showSmall: '',
    overflow: '',
    text: "",
    stopWrite: false,
    }),
  computed:{
    img(){
      return this.$store.state.news.img.replace('1000', '1920') || '/img/fundo_homem.png'
    },
    originalText(){
      return 'Saiba mais!'
    }
  },
  watch:{
    originalText(v){
      this.stopWrite = true
      this.text = v
    }
  },
  methods: {
    async write() {
      let total = this.originalText.length;
      while (total) {
        if(this.stopWrite) break;
        this.text += this.originalText[this.originalText.length - total];
        total--;
        await this.timeout(50);
      }
      await this.timeout(this.$store.state.previews.timeStage2)
      this.$emit('end')
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    setTimeout(()=>this.showSmall='show-small',50)
    setTimeout(()=>this.show = 'show', 50)
    setTimeout(()=>{
      this.logoShow = 'logo-show'
      this.overflow='overflow'
      }, 1000)
    this.write();
  },
};
</script>

<style  scoped>

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: tn;
}

.content *{
  transition: all .5s ease;
}

.background-black{
  background: rgba(0,0,0,0.5);
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.show{
  opacity: 1;
}

.logo{
  position: absolute;
  width: 400px;
  text-align: center;
}

.logo3{
  top: 5%;
  left: 340px;
  width: 400px;
  height: 0px;
  overflow: hidden;
}

.logo-show{
  height: 500px;
}

.text {
  top: 58%;
  text-align: center;
  font-size: 60pt;
  position: absolute;
  width: 100%;
  font-weight: bold;
}

.img {
  position: absolute;
  height: 0px;
  width: 0px;
  left: 240px;
  bottom: 100px;
  transform: translate(0, 0);
  animation: wave 10s infinite linear;
  overflow: hidden;
  background: #fff;
  /* border-radius: 100% 30% 80% 50%; */
}

.show-small{
  height: 600px;
  width: 600px;
}

.overflow{
  overflow: initial
}

@keyframes wave {
  0% {
    border-radius: 60% 80% 80% 50%;
  }

  20% {
    border-radius: 100% 50% 50% 80%;
  }

  40% {
    border-radius: 30% 70% 10% 70%;
  }

  60% {
    border-radius: 88% 50% 80% 50%;
  }

  80% {
    border-radius: 60% 80% 90% 100%;
  }

  100% {
    border-radius: 60% 80% 80% 50%;
  }
}
</style>