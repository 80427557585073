<template>
 
    <div class="prev">
      <img
        :src="$store.state.news.link"
        alt=""
        v-if="$store.state.news.mediaType == 'Imagem'"
      />
      <video
        v-else-if="$store.state.news.mediaType == 'Vídeo' && $store.state.news.link"
        :src="$store.state.news.link"
        muted
        autoplay
      ></video>
      <Qr :color="$store.state.news.color" v-if="$store.state.news.showQr" />
    </div>

</template>

<script>
import Qr from "./Qr.vue"
export default {
  components:{Qr},
  name: "PreviewMedia",
};
</script>

<style scoped>
.prev {
    position: relative;
  width: 1080px;
  height: 1920px;
}
video,
img {
  width: 1080px;
  height: 1920px;
}
.imgPrev{
    position:absolute;
    bottom: 200px;
    right: 100px;
    background: #fff;    
    width: 280px;
    height: 280px;
    border-radius: 50px;
    border: solid 10px #000;
    overflow: hidden;
    text-align: center;
}
.imgPrev img{
    
width: 180px;
height: 180px;
bottom: 0;
}
.leiamais{
  font-family: arial;
  text-align: center;
padding-bottom: 20px;
  font-weight: 900;
  font-size: 25pt;
  font-family: monospace;
}
</style>