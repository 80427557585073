<template>
  <div :class="['content', classClose]" @click="toggle">
    <Stage1 v-if="!showFrame2 && (template.frame == 1 || !template.name)" @end="nextFrame" :template="template" />
    <Stage2 v-if="showFrame2 || template.frame == 2" @end="nextFrame" />
  </div>
</template>

<script>
import Stage1 from "./stage1.vue";
import Stage2 from "./stage2.vue";
export default {
  components: { Stage1, Stage2 },
  props: { template: { type: Object, default: () => ({ frame: "Frame 1" }) } },
  data: () => ({
    stage1: true,
    classClose: "",
    showFrame2: false
  }),
  methods: {
    nextFrame(){
      if(!this.template.last){
        this.$emit('end', this.template.index)
      }
      else{
        if(this.template.last && this.showFrame2){
        return this.$emit('end', this.template.index)
      }
        this.showFrame2 = true
      }

      
    },
    toggle() {
      this.showFrame2 = !this.showFrame2
    },
  }
};
</script>

<style  scoped>
@font-face {
  font-family: "tn";
  src: url("/fonts/HurmeGeometricSans1.otf");
}

@font-face {
  font-family: "tn-bold";
  src: url("/fonts/HurmeGeometricSans1_Bold.otf");
}

@font-face {
  font-family: "tn-semibold";
  src: url("/fonts/HurmeGeometricSans1_SemiBold.otf");
}
.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 1;
}

.content-close {
  opacity: 0;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>