<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
    <v-col md="6">
      <v-text-field
        v-if="$store.state.user.type != 'Parceiro'"
        outlined
        v-model="$store.state.news.time"
        label="Tempo em segundos de exibição"
        dense
        type="number"
        :rules="[(v) => v > 0 || 'Tempo em segundos que a notícia ficará na tela do totem']"
      ></v-text-field>
    </v-col>
    <v-col md="6">
      <v-select 
      hide-details
        :items="['Padrão', 'Imagem', 'Vídeo']"
        v-model="$store.state.news.mediaType"
        outlined
        label="Tipo de mídia"
        dense
        type="number"
      ></v-select >
    </v-col>
    </v-row>

<v-expand-transition>

        <div v-show="['Vídeo', 'Imagem'].includes($store.state.news.mediaType)">

        <UploadMedia />
        </div>
</v-expand-transition>

<v-expand-x-transition>
    <v-text-field
    v-show="!['Vídeo', 'Imagem'].includes($store.state.news.mediaType)"
      outlined
      label="Cole aqui o link desta notícia"
      v-model="$store.state.news.link"
      :rules="validateLink"
      required
      @keyup="changeCrowller"
      small
    ></v-text-field>
    </v-expand-x-transition>

    <v-text-field
      outlined
      v-model="$store.state.news.text"
      :counter="300"
      :rules="nameRules"
      label="Título Principal"
      required
      small
    ></v-text-field>

    <v-text-field
      outlined
      v-model="$store.state.news.copyright"
      label="Crédito da imagem"
      small
    ></v-text-field>

      <v-file-input
        small
        outlined
        accept="image/*"
        :loading="loading"
        v-model="img"
        show-size
        filled
        
        
        color="green"
        :label="'Imagem Principal: '+$store.state.news.img"
        :title="$store.state.news.img"
        prepend-icon=""
        :rules="rulesImg"
      ></v-file-input>

    
      <v-file-input
        outlined
        accept="image/*"
        show-size
        filled
        
        :label="'logo: ' + $store.state.news.logo"
        v-model="logo"
        @change="changeLogo"
        prepend-icon=""
        :rules="rulesLogo"
      ></v-file-input>


      <v-autocomplete
            v-model="$store.state.news.userSelected"
            :items="$store.state.users.filter(user => user.type == 'Parceiro')"
            item-value="_id"
            item-text="name"
            outlined
            filled
            label="Parceiro"
            @change="setColor"
            :disabled="$store.state.user.type=='Parceiro' ? true : false"
            :rules="[v=>(v || []).length > 0 || 'Parceiro é obrigatório']"
          ></v-autocomplete>

    <picker></picker>
    <v-card-actions>
      <v-btn color="error" class="mr-4" @click="reset">cancelar</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        :loading="loadingSave"
        @click="save"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { createNews, updateNews } from "../../services/news";
import picker from "./datePicker";
import { changeCrowller } from "../../services/crowller";
import { sendImageLogo, sendImageNews } from "../../services/repository";
import UploadMedia from './UploadMedia.vue'
export default {
  components: { picker,UploadMedia },
  data: () => ({
    loadingSave: false,
    logo: [],
    loading: false,
    img: [],
    menu: false,
    showColors: false,
    valid: false,
    name: "",
    validateLink: [
      (v) => (v || []).length > 0 || "Link da notícia é obrigatório",
    ],
    nameRules: [(v) => !!v || "Preencha um texto acima"],
    copyrightRules: [
      (v) =>
        (v || []).length > 0 || "Coloque o nome de de quem originou a imagem",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    timeout: 0,
    url: ""
  }),

  computed: {
    rulesLogo() {
      return [
        () =>
          this.$store.state.news.logo.length > 0 ||
          "Favor selecionar um logotipo",
      ];
    },
    rulesImg() {
      return [
        () =>
          ['Vídeo', 'Imagem'].includes(this.$store.state.news.mediaType) || this.$store.state.news.img.length > 0 ||
          "Favor selecionar uma imagem principal",
      ];
    },
  },
  methods: {
    setColor(userId){
      const user = this.$store.state.users.find(user => user._id == userId)
      this.$store.state.news.color = user.color
      this.$store.state.news.logo = user.logo;
    },
    async save() {
      this.loadingSave = true;
      const validate = this.$refs.form.validate();
      if (!validate) return (this.loadingSave = false);
      const data = Object.assign({}, this.$store.state.news);
      delete data.previewNews;
      data.user = this.$store.state.users.find(user => user._id == this.$store.state.news.userSelected);
      data.externalId = data.link
      console.log(data)
      if (this.$store.state.news.edited) {
        await updateNews([data])
          .then((msg) => this.$store.commit("success", msg))
          .catch((err) => {
            this.loadingSave = false;
            return Promise.reject(err);
          });
      } else {
        delete data._id;
        await createNews(data)
          .then((msg) => this.$store.commit("success", msg))
          .catch((err) => {
            this.loadingSave = false;
            return Promise.reject(err);
          });
      }
      this.$store.state.snackbar = true;
      this.loadingSave = false;
      this.$router.push("/news/list");
    },
    validUrl() {
      try {
        let isEqual = this.url == this.$store.state.news.link;
        isEqual = this.$store.state.news.changeCrowller ? false : isEqual;
        if (isEqual || !this.$store.state.news.link) return false;
        new URL(this.$store.state.news.link);
        this.$store.state.news.changeCrowller = false;
        return true;
      } catch (error) {
        return false;
      }
    },
    changeCrowller() {
      if (!this.validUrl()) return;
      this.url = this.$store.state.news.link;
      this.$store.state.news.loadingNews = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        changeCrowller(this.$store.state.news.link)
          .then((data) => {
            this.$store.state.news.previewNews = data;
            this.$store.state.news.loadingNews = false;
          })
          .catch(() => (this.$store.state.news.loadingNews = false));
      }, 1500);
    },
    reset() {
      this.$refs.form.reset();
      this.$store.state.news = Object.assign(
        {},
        this.$store.state.originalNews
      );
      this.$router.push("/news/list");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async changeLogo(value) {
      if (!(value || {}).name) return;
      this.loading = true;
      const url = await sendImageLogo(value);
      this.loading = false;
    
      this.$store.state.news.logo = url.url;
      this.$refs.form.validate();
    },
  },
  watch: {
    "$store.state.news.changeCrowller"() {
      this.changeCrowller();
    },
    "$store.state.user"(){
      if(this.$store.state.user.type=='Parceiro'){
      this.$store.state.users = [this.$store.state.user]
      this.$store.commit('setUserSelected', this.$store.state.user._id)
    }
    },
    async img(value) {
      if (!(value || {}).name) return;
      this.loading = true;
      const url = await sendImageNews(value);
      this.loading = false;
      this.$store.state.news.img = url.url;
      this.$refs.form.validate();
    },
  }
};
</script>