<template>
  <v-card
    class="mx-auto "
    width="500"
    height="850"
  >
    <v-card-title>Notícia <v-spacer></v-spacer>
    <v-switch
          v-if="$store.state.user.type != 'Parceiro'"
          v-model="$store.state.news.status"
          inset
          :label="
            'Status ' + ($store.state.news.status ? 'ativo' : 'inativo')
          "
          hide-details
        />
    </v-card-title>
    <v-card-text><cardForm/></v-card-text>
    
  </v-card>
</template>

<script>
import cardForm from './form.vue'
  export default {
    components:{cardForm},
    data () {
      return {
        labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
        time: 0,
        forecast: [
          { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
          { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
          { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
        ],
      }
    }
  }
</script>
