<template>
<div class="marquee">
    <div class="slider">
      <div class="slide-track">
        <div class="slide">
          <img
            :src="['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
        <div class="slide">
          <img
            :src="['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
        <div class="slide">
          <img
            :src="['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>

        <div class="slide">
          <img
            :src="['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
        <div class="slide">
          <img
            :src="['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
        <div class="slide">
          <img
            :src="['#000', '#000000'].includes(($store.state.news.user || {}).textColor) ? '/img/TN_PRETO.png' : '/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
};
</script>

<style  scoped>

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 3));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 3));
  }
}

.marquee {
  align-items: center;
  position:absolute;
  top: 10px;
  display: flex;
  height: 250px;
  justify-content: center;
  width: 100vw;
}
.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  height: 200px;
  width: 100%;
}
.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 5s linear infinite;
  animation: scroll 5s linear infinite;
  display: flex;
  width: calc(400px * 6);
}
.slider .slide {
  height: 100px;
  width: 500px;
}
</style>