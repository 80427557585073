<template>
<div>
    <div class="slider">

        <div :class="['slide slide2', slideUp]">
          <img
            :src="'/img/TN_BRANCO.png'"
            width="400"
            alt=""
          />
        </div>
        
      
    </div>
  </div>
</template>

<script>
export default {
  props:["notAnimation"],
  data:()=>({
    slideUp: '',
    slideUp2: ''
  }),
  mounted(){
    if(this.notAnimation)return this.slideUp = 'slide-up'
    setTimeout(()=>{
      this.slideUp = 'slide-up'
      //setTimeout(()=>this.slideUp2 = 'slide-up', 200)
      }
      , 50)
  }
};
</script>

<style  scoped>

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 3));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-400px * 3));
  }
}



.slider {
  overflow: hidden;
  position: relative;
  height: 500px;
  width: 100%;
  margin-top: -250px;
  
}

.slide {
  width: 400px;
  position:absolute;
}

.slide2{
  left: calc(50% - 200px);
  top: -250px;
  transition: all 2.2s ease-in-out;
}

.slide-up{
 top:350px; 
}
</style>