<template>
  <v-card
    class="mx-auto"
    width="500"
    height="850"
    style="overflow: auto"
    :loading="loading"
  >
    <v-card-title>
      Site da notícia<v-spacer></v-spacer>
      
      <v-btn
      class="ma-2"
      :loading="loading"
      :disabled="loading"
      color="info"
      fab
      small
      @click="$store.state.news.changeCrowller = Date.now()"
    >
      <v-icon light>mdi-cached</v-icon>
    </v-btn>

    </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, i) in Object.keys(($store.state.news || {}).previewNews)"
          :key="i"
        >
          <v-expansion-panel-header>
            {{ content[item] }}
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="item == 'imgs'">
            <img
              width="100"
              styled="cursor:pointer"
              @click="$store.state.news.img = img"
              :src="img"
              v-for="(img, index) in ($store.state.news || {}).previewNews[item]"
              :key="index"
            />
          </v-expansion-panel-content>
          <v-expansion-panel-content v-if="item == 'divs'">
            <div
              class="content"
              @click="getText"
              style="cursor: pointer"
              v-for="(div, index) in ($store.state.news || {}).previewNews[item]"
              :key="index"
              v-html="div"
            ></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-html="$store.state.news.previewNews.data"></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      content: {
        imgs: "Imagens",
        a: "Links",
        divs: "Conteúdos",
      },
    };
  },
  computed: {
    loading() {
      return (this.$store.state.news || {}).loadingNews;
    },
  },
  methods: {
    getText(e) {
      this.$store.state.news.text = e.path[0].innerText;
    },
  },
};
</script>
<style scoped>
.content >>> div {
  background-color: transparent;
  border-radius: 5px;
  border: solid 1px rgba(3, 169, 219, 0.692);
  margin-top: 5px;
}
.content >>> div:hover {
  background-color: rgba(92, 144, 255, 0.301);
  box-shadow: 1px 1px 3px #000;
}
</style>